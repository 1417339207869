import 'select2'
import 'select2/dist/css/select2.css'
import "select2-bootstrap-theme/dist/select2-bootstrap.css";

$(document).ready(function() {

  $('.custom-select').not('.select2-hidden-accessible').select2({
    theme: "bootstrap",
    width: '100%',
    language: { noResults: () => I18n.t('placeholders.select_no_results')}
    //closeOnSelect: false
  });

  $('#focus_factory_ids_, #user_setting_focus_factory_ids').on('select2:select', function (e) {
    var focus_factory_id = $('.select-focus_factory').val();

    $.ajax({
      type: "get",
      url: "/teams/by_focus_factory",
      data: {focus_factory_id: focus_factory_id},
      success: function (json) {
        $(".select-team").replaceOptions(json);
      }
    })

    $.ajax({
      type: "get",
      url: "/lines/by_focus_factory",
      data: {focus_factory_id: focus_factory_id},
      success: function (json) {
        $(".select-line").replaceOptions(json);
      }
    })
  });  

  $('#team_ids_, #user_setting_team_ids').on('select2:select', function (e) {
    var team_id = $('.select-team').val();

    $.ajax({
      type: "get",
      url: "/lines/by_team",
      data: {team_id: team_id},
      success: function (json) {
        $(".select-line").replaceOptions(json['data']);
        var arr = $(".select-focus_factory").val();
        arr.push(json['focus_factory_id']);
        $(".select-focus_factory").val(arr).trigger('change');
      }
    })
  });  

  $('#line_ids_, #user_setting_line_ids').on('select2:select', function (e) {
    var line_id = $('.select-line').val();

    $.ajax({
      type: "get",
      url: "/teams/by_line",
      data: {line_id: line_id},
      success: function (json) {
        var ff_arr = $(".select-focus_factory").val();
        $(".select-focus_factory").val($.merge(ff_arr, json['focus_factory_id'])).trigger('change');

        var t_arr = $(".select-team").val();
        $(".select-team").val($.merge(t_arr, json['team_id'])).trigger('change');
      }
    })
  });  

  //Initialization of treeviews
  $('#tree').treed();
});


(function($, window) {
  $.fn.replaceOptions = function(options) {
    var self, $option;

    this.empty();
    self = this;

    $.each(options, function(index, option) {
      $option = $("<option></option>")
        .attr("value", option.value)
        .text(option.text);
      self.append($option);
    });
  };
})(jQuery, window);


$.fn.extend({
  treed: function (o) {
    
    var openedClass = 'fa-minus-square';
    var closedClass = 'fa-plus-square';
    
    if (typeof o != 'undefined'){
      if (typeof o.openedClass != 'undefined'){
      openedClass = o.openedClass;
      }
      if (typeof o.closedClass != 'undefined'){
      closedClass = o.closedClass;
      }
    };
    
    //initialize each of the top levels
    var tree = $(this);
    tree.addClass("tree");
    tree.find('li').has("ul").each(function () {
        var branch = $(this); //li with children ul
        branch.prepend("<a class='node' href='#'><span class='grey-color'><i class='indicator fa " + openedClass + "'></i></span></a>");
        branch.addClass('branch');
        branch.on('click', function (e) {
          if (this == e.target) {
            var icon = $(this).find('svg:first');
            icon.toggleClass(openedClass + " " + closedClass, 300);
            $(this).children('ul').children().fadeToggle(300);

          }
        })
        //branch.children().children().toggle();
    });

    //fire event from the dynamically added icon
    tree.find('.indicator').each(function () {
      $(this).on('click', function () {
        $(this).closest('li').click();
      });
    });

    //fire event to open branch if the li contains an anchor instead of text
    tree.find('a.node').each(function () {
      $(this).on('click', function (e) {
        $(this).closest('li').click();
        e.preventDefault();
      });
    });

    //fire event to open branch if the li contains a button instead of text
    tree.find('.branch>button').each(function () {
      $(this).on('click', function (e) {
        $(this).closest('li').click();
        e.preventDefault();
      });
    });
  }
});